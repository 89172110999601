const routes = [
  // {
  //   resource: "application",
  //   icon: "ArchiveBox",
  //   name: "Applications",
  //   path: "application",
  // },
  {
    resource: "application",
    icon: "ArchiveBox",
    name: "Applications",
    path: "application",
    routes: [
      {
        icon: "ExclamationCircle",
        path: "application/pending",
        name: "Pending",
      },
      {
        icon: "HandThumbUp",
        path: "application/approved",
        name: "Approved",
      },
      {
        icon: "ExclamationTriangle",
        path: "application/rejected",
        name: "Rejected",
      },
    ],
  },
  {
    resource: "application",
    icon: "QueueList",
    name: "Microapp Categories",
    path: "category",
  },
  {
    resource: "application",
    icon: "RocketLaunch",
    name: "Promotions",
    path: "promotion",
  },
  {
    resource: "user",
    icon: "UserGroup",
    name: "Users",
    path: "user",
  },
];

export default routes;
