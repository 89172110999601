import axios from "axios";
// eslint-disable-next-line no-unused-vars

// export const endpoint = "https://1sea-admin-portal-backend.frp.t05.sg";
const endpoint = `https://api.admin.1sea.io`;

const instance = axios.create({ baseURL: endpoint });

const API = (method, url, data, options) => {
  return instance({ method, url, data, withCredentials: true, ...options });
};

export default API;
