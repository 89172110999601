import pages from "../pages/index";

const routes = [
  //   User Management
  {
    resource: "user",
    path: "user",
    element: pages.User,
    children: [
      {
        path: "new",
        element: pages.CreateUser,
      },
    ],
  },
  {
    resource: "application",
    path: "category",
    element: pages.Category,
    children: [
      {
        path: "new",
        element: pages.CreateCategory,
      },
    ],
  },
  {
    resource: "application",
    path: "promotion",
    element: pages.Promotion,
    children: [
      {
        path: "new",
        element: pages.NewPromotion,
      },
      {
        path: ":id",
        element: pages.BannerDetails,
      },
    ],
  },
  {
    resource: "application",
    path: "application",
    element: pages.Application,
    children: [
      {
        path: ":id",
        element: pages.AppDetails,
      },
      {
        path: "pending",
        element: pages.Pending,
      },
      {
        path: "approved",
        element: pages.Approved,
      },
      {
        path: "rejected",
        element: pages.Rejected,
      },
    ],
  },
];

export default routes;
