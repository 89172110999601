import pages from "../pages/index";

const routes = [
  {
    path: "/login",
    element: pages.Login,
  },
];

export default routes;
