import { lazy } from "react";

const pages = {
  Page404: lazy(() => import("./404")),
  // Login
  Login: lazy(() => import("./Login")),
  // User Account
  User: lazy(() => import("./User")),
  CreateUser: lazy(() => import("./User/CreateNewUser")),
  // Applications
  Pending: lazy(() => import("./Applications/Pending")),
  Rejected: lazy(() => import("./Applications/Rejected")),
  Approved: lazy(() => import("./Applications/Approved")),
  AppDetails: lazy(() => import("./Applications/AppDetails")),
  // Category
  Category: lazy(() => import("./Category")),
  CreateCategory: lazy(() => import("./Category/CreateCategory")),
  // Promotion
  Promotion: lazy(() => import("./Promotion")),
  NewPromotion: lazy(() => import("./Promotion/NewPromotion")),
  BannerDetails: lazy(() => import("./Promotion/BannerDetails")),
  // CreateAccount: lazy(() => import("./User/Account/CreateAccount")),
  // EditAccount: lazy(() => import("./User/Account/EditAccount")),
  // ChangePassword: lazy(() => import("./User/Account/ChangePassword")),
  // ChangePass: lazy(() => import("./User/Account/ChangePass")),
};

export default pages;
